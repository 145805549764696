import firebase from "firebase";

// const firebaseConfig = {
//     apiKey: 'AIzaSyCGCTOwaeK63q3SveO-MECIwr7j7f_MYB0',
//     authDomain: 'llab-development.firebaseapp.com',
//     databaseURL: 'https://llab-development.firebaseio.com',
//     projectId: 'llab-development',
//     storageBucket: 'llab-development.appspot.com',
//     messagingSenderId: '196975445957',
//     appId: '1:196975445957:web:f6831d6f4415506e'
//   };

const firebaseConfig = {
  apiKey: "AIzaSyAsqugBQhV81cx95Y_7yzZVIpbHFQiaWWI",
  authDomain: "llab-order-request.firebaseapp.com",
  databaseURL: "https://llab-order-request.firebaseio.com",
  projectId: "llab-order-request",
  storageBucket: "llab-order-request.appspot.com",
  messagingSenderId: "401572587436",
  appId: "1:401572587436:web:8837a7453155bde88c7c09",
  measurementId: "G-S3XREL015V",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export { db };
